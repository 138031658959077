<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0 pb-1">
        <b-link class="brand-logo">
          <b-img :src="logoUic" />
        </b-link>

        <b-card-title class="mb-1">
          Welcome to Evote!
        </b-card-title>
        <b-card-text class="mb-2">
          Please sign-in to your account.
        </b-card-text>

        <!-- form -->
        <validation-observer
          ref="loginForm"
          #default="{ invalid }"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <!-- email -->
            <b-form-group
              label-for="email"
              label="Email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="userEmail"
                  name="login-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Password</label>
                <!-- <b-link>
                  <small>Forgot Password?</small>
                </b-link> -->
              </div>
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    placeholder="Password"
                    autocomplete="on"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="login"
            >
              Sign in
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BImg,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import API from '@/api'
import { setUserStorage } from '@/helpers/getDataFromStorage'
import { initialAbility } from '@/libs/acl/config'
import logoUic from '@/assets/images/logo-uic.png'
import getFormatUicDevice from '@/helpers/getFormatUicDevice'

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BImg,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      status: '',
      // validation rules
      required,
      email,
      logoUic,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    async login() {
      let isRedirect = false
      try {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', true)

        const form = {
          password: this.password,
          email: this.userEmail,
        }
        const formatDevice = await getFormatUicDevice(this.userEmail)
        const { data } = await API.auth.loginTpsForWebOnly(form, formatDevice)
        this.$store.commit('user/UPDATE_USER_LOGGED_IN', data.user)
        const {
          VUE_APP_X_UIC_VERSION,
          VUE_APP_X_API_VERSION,
          VUE_APP_X_UIC_PLATFORM,
        } = process.env
        setUserStorage({
          tokenCurrent: data.token,
          isOtp: false,
          roleId: data.user.role_id || 0,
          deviceInfo: formatDevice,
          appVersion: VUE_APP_X_UIC_VERSION,
          apiVersion: VUE_APP_X_API_VERSION,
          platform: VUE_APP_X_UIC_PLATFORM,
        })
        this.$ability.update(
          initialAbility[data.user.role_id] || initialAbility[0],
        )
        this.handleMsgSuccess('Successfully logged in')
        isRedirect = true
      } catch (error) {
        console.log(error)
        this.handleMsgErrors(
          error,
          'Email atau Password salah, silahkan coba lagi!',
        )
      } finally {
        this.$store.commit('SET_IS_LOADING_FOR_SUBMIT', false)
        if (isRedirect) {
          this.$router.push({ path: '/otp', replace: true })
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
